import * as React from 'react';
import { useEffect, useState } from 'react';
import useRaf from "@rooks/use-raf";
import isIos from '../helper/is-ios'

type Props = {
    children: React.ReactNode
}


export default function ({ children }: Props) {
    const [scale, setScale] = useState(1)

    useRaf(() => {
        const aspectRatio = window.innerWidth / window.innerHeight;
        if(isIos && aspectRatio > 2) return; // Bail out if keyboard is showing
        setScale(Math.min(window.innerWidth / 1920, window.innerHeight / 1080)); 
    }, true)

    useEffect(function () {
        setWindowScale();
    }, [scale])

    function setWindowScale(){
        const offsetX = ((window.innerWidth / scale) - 1920) / 2;
        const offsetY = ((window.innerHeight / scale) - 1080) / 2;
        console.log(window.innerWidth / window.innerHeight)
        document.body.style.setProperty('--scale', `${scale}`);
        document.body.style.setProperty('--offset-x', `${offsetX}px`);
        document.body.style.setProperty('--offset-y', `${offsetY}px`);
    }

    return (
        <div className="ScaleContainer">
            { children}
        </div>
    )
}
