import React from "react";

type Props = {
    children:React.ReactNode
}


export default function Background({children}:Props){
    return (
        <div className="Background">
            { children }
        </div>
    )
}
