import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Context as AppRouteContext } from './AppRoute'


export default function({item}:{item:any}){
    const ctx = useContext(AppRouteContext);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if(loading) ctx.actions.startLoading(item.src);
        else ctx.actions.endLoading(item.src);
    }, [loading])
    
    const endLoading = useCallback(()=>{
        setLoading(false);
    }, [])

    useEffect(() => {
        if(item.complete) setLoading(false)
        else item.onload = endLoading;
    }, []);

    return <></>
}
