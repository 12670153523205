import { useRef, useState } from "react"
import ImageLoader from './ImageLoader'

type Props = {
    childA:React.ReactNode,
    childB:React.ReactNode,
}


export default function({childA, childB}:Props){
    const refCover = useRef<HTMLDivElement>(null);
    const refA = useRef<HTMLDivElement>(null);
    const refB = useRef<HTMLDivElement>(null);

    const [state, setState] = useState({
        isDown: false,
        percent: 0.5,
    });


    function handleTouch(x:number, isDown:boolean){
        if(isDown===false || state.isDown===false){
            setState({
                ...state,
                isDown:isDown!
            })
        }else{
            const rect = refA.current!.getBoundingClientRect();
            const relX = x - rect.x;
            var percent = relX / rect.width;
            if(percent < 0) percent = 0;
            if(percent > 1) percent = 1;
            setState({
                ...state,
                percent,
                isDown,
            });
        }
    }


    const width = refA.current?.clientWidth!;
    const height = refA.current?.clientHeight!;

    const styleCover = {
        width:width+'px',
        height:height+'px',
    }

    const styleB = {
        clip: `rect(0px,${width*state.percent}px,9999px,0px)`
    }


    const styleIndicator = {
        height: (height-4)+'px',
        width: '2px',
        left: (width*state.percent)+'px',
    }

    const styleIndicatorIcon = {
        height: height+'px',
        width: '2px',
        left: (width*state.percent)+'px',
        top: (height/2)+'px',
    }

    return (
        <div className="SlideReveal" style={styleCover}>
            <div className="SlideRevealChild" ref={refB}>{ childA }</div>
            <div className="SlideRevealChild" style={styleB} ref={refA}>{ childB }</div>
            <div className="SlideRevealCover" style={styleCover} ref={refCover}>
                <div className="SliderRevealIndicator" style={styleIndicator} />
                <div className="SliderRevealIndicatorIcon" style={styleIndicatorIcon}>
                    <ImageLoader src={require('../images/interface/btn-slide.png').default} />
                </div>
            </div>
            <div className="SliderRevealTouchZone" style={styleCover}
                onTouchStart={e=>handleTouch(e.touches[0].clientX, true)}
                onTouchMove={e=>handleTouch(e.touches[0].clientX, state.isDown)}
                onTouchEnd={e=>handleTouch(state.percent, false)}
                onMouseDown={e=>handleTouch(e.clientX, true)}
                onMouseMove={e=>handleTouch(e.clientX, state.isDown)}
                onMouseUp={e=>handleTouch(state.percent, false)}
            ></div>
        </div>
    )
}
