import { useEffect, useRef, useState } from 'react';
import { Context as AppRouteContext } from './AppRoute'


export default function({src, width, height}:{src:string, width?:number, height?:number}){    
    return (
        <AppRouteContext.Consumer>
            {
                (state) => (
                    <Img src={src} width={width} height={height} startLoading={state.actions.startLoading} endLoading={state.actions.endLoading} />
                )
            }
        </AppRouteContext.Consumer>
    )
}


function Img({src, startLoading, endLoading, width, height}:{width?:number, height?:number, src:string, startLoading:(id:string)=>void, endLoading:(id:string)=>void}){
    const ref = useRef<HTMLImageElement>(null);
    const [loading, setLoading] = useState(!ref.current?.complete);

    useEffect(function(){
        if(loading) startLoading(src);
        else endLoading(src);
    }, [src, loading, startLoading, endLoading]);

    useEffect(function(){
        return () => {
            setLoading(false);
        }
    }, [setLoading])

    function onLoaded(){
        setLoading(false);
    }
    
    return <img draggable={false} src={src} alt={src} onLoad={onLoaded} ref={ref} data-width={width ? width : false} data-height={height ? height : false}/>
}