import ImageLoader from './ImageLoader';


type Props = {
    options:{
        overlay:string,
        id:string,
    }[],
    selection:string|null
}


const Option = (selection:string|null) => (option:Props['options'][number]) => {
    const selected = selection === option.id
    return (
        <div className="ImageSelectorOption" data-hide={!selected} key={option.overlay}>
            <ImageLoader src={option.overlay} />
        </div>
    )
}


export default function({options, selection}:Props){
    return (
        <div className="ImageSelector">
            { options.map(Option(selection)) } 
        </div>
    );
}