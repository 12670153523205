export type State = { errors: typeof initialState }

const initialState = {
    count: 1,
    id: 'aaa'
}


const inc = () => (state:State) => {
    state.errors.count++;
}


const setId = (id:string) => (state:State) => {
    state.errors.id = id;
}


export default {
    initialState,
    actions:{
        inc,
        setId,
    }
}
