export type State = { menu: typeof initialState };


const initialState = {
    item: null as null|string
}


const setItem = (item:null|string) => (state:State) => {
    state.menu.item = item;
}


export default {
    initialState,
    actions:{
        setItem,
    }
}
