import ImageLoader from "./ImageLoader"

export default function(){
    return (
        <div className="FloorplanKey">
            <ImageLoader src={require("../images/floorplans/img--legend.png").default} />
        </div>
    )
}

