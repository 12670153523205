import { SalesData } from "../data/sales-data";

export type State = { data: typeof initialState };


const initialState = {
    sales:null as SalesData|null,
    requestSales:false,
}


const setSalesData = (data:SalesData) => (state:State) => {
    state.data.sales = data;
    state.data.requestSales = false;
}


const requestSales = () => (state:State) => {
    state.data.requestSales = true;
}


export default {
    initialState,
    actions:{
        setSalesData,
        requestSales,
    }
}
