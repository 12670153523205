type Props = {
    onSelected:(id:string)=>void,
    debug?:boolean,
    items:{
        src:string,
        x:number,
        y:number,
        width:number,
        height:number
    }[]
}


const ThumbnailPickerItem = (onSelected:Props['onSelected']) => (item:Props['items'][number]) => {
    function onClick(){
        onSelected(item.src);
    }

    return (
        <div className="ThumbnailPickerItem" onMouseDown={onClick} key={item.src} style={{
            left: item.x + "px",
            top: item.y + "px",
            width: item.width + "px",
            height: item.height + "px",
        }} />
    )
}


export default function({debug, onSelected, items}:Props){
    return (
        <div className="ThumbnailPicker" data-debug={debug}>
            { items.map(ThumbnailPickerItem(onSelected)) }
        </div>
    )
}
