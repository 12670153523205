type Props = {
    title?:string,
    onClick:Function,
}


export default function({onClick,title}:Props){
    return (
        <div className="SubmitButton" onClick={(e) => onClick()}>{title || 'SUBMIT'}</div>
    )

}