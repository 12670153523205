import React from "react";


type Props = {
    children:React.ReactNode,
    width:number,
    justifyContent: "flex-start" | "center",
    paddingSide?: number,
    paddingTop?: number,
}


export default function({children, width, justifyContent, paddingSide=0, paddingTop=0}:Props){
    return (
        <div className="SidePanel" style={{width:width+'px'}}>
            <div className="SidePanelInnerShadow"/>
            <div className="SidePanelItems" style={{justifyContent, paddingTop:paddingTop+'px', paddingLeft:paddingSide+'px',paddingRight:paddingSide+'px'}}>
                { children }
            </div>
        </div>
    )
}