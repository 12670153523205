import * as React from 'react'

type Props = {
    num:number,
    selected:number,
}


export default function(props:Props){
    var arr = [];
    for(var i = 0; i < props.num; i++) arr.push(i==props.selected);
    return (
        <div className="dots">
            { arr.map(item=> <div className="dot" data-selected={item==true}></div>) }
        </div>
    )
}
