import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { Context } from './AppRoute'
import React from "react";



export function Spinner({loading}:{loading:boolean}){
    return (
        <div className="Spinner" data-loading={loading}>
            <FontAwesomeIcon icon={faCircleNotch} spin={true} color='white' size='4x'/>
        </div>
    )
}


export function DumbLoadingIndicator({loading, children}:{loading:boolean, children:React.ReactNode}){
    return (
        <div className="LoadingIndicator">
            { <Spinner loading={loading}/> }
            <div className="LoadingIndicatorContent" data-loading={loading}>{ children }</div>
        </div>
    )
}


export default function({children, required}:{required:boolean, children:React.ReactNode}){
    return (
        <Context.Consumer>
            { value => 
                <DumbLoadingIndicator loading={required && value.state.isLoading}>
                    { children }
                </DumbLoadingIndicator>
            }
        </Context.Consumer>       
    )
}