// https://kentcdodds.com/blog/how-to-use-react-context-effectively

import * as React from 'react'
import produce from 'immer'
import { useState } from 'react'


type ActionCreator<F> = F extends (...args:infer P) => (state:infer S) => void ? F : never;
type ActionCreators<T> = { [key in keyof T]: T[key] extends ActionCreator<T[key]> ? ActionCreator<T[key]> : T[key] extends {} ? ActionCreators<T[key]> : never }
type Actions<T extends ActionCreators<any>> = {
    [key in keyof T]: T[key] extends ActionCreator<T[key]> ? (...args:Parameters<T[key]>)=>void : T[key] extends ActionCreators<T[key]> ? Actions<T[key]> : never
}


/*
type State = {}

const actions = {
    inc: () => (state:State) => state,
    setId: (id:String) => (state:State) => state,
    fields: {
        doSomething: () => (state:State) => state
    }
}
*/



export function createImmerContext<S, A extends ActionCreators<any>>(initialState:S, actions:A){
    type ContextType = { state:S, actions:Actions<A> }
    type ProviderProps = {children: React.ReactNode}

    const Context = React.createContext<ContextType>(null!)

    function Provider({children}:ProviderProps){
        const [state, setState] = useState(initialState);
        
        function createActions<T extends ActionCreators<any>>(creators:T):Actions<T>{
            var result:any = {};
            Object.keys(creators).forEach(key => {
                switch(typeof creators[key]){
                    case 'object': result[key] = createActions(creators[key]); break;
                    case 'function':
                        result[key] = (...args:Array<any>) => { 
                            var action = creators[key]
                            setState( produce(draft => action.apply(null, args)(draft) ))
                        }
                        break;
                    default: throw(new Error("Unsupported"))

                }
            });  

            return result;
        }
        

        const toProvide = {
            state,
            actions: createActions(actions),
        }

        return (
            <Context.Provider value={toProvide}>
                { children }
            </Context.Provider>
        )
    }

    return {
        Provider,
        Context
    }

}

