import React from "react";


export default function({children}:{children:React.ReactNode}){
    return (
        <div className='Form'>
            <form>
                { children }   
            </form>
        </div>
    )
}
